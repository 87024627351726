import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import blogs from './data'; // Array of blog objects with id, title, description, image, and content properties
import './Blog.css';
import SEO from '../../components/SEO';
import HeroComponent from '../../components/Hero';
import TelegramQR from "../../../src/assests/images/telegram_QR.png";
import WhatsAppQR from "../../../src/assests/images/WhatsApp_QR.png";
import { submitContactForm } from '../../services';

const Sidebar = ({ blogs }) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = () => {
    if (searchText.trim() === "") {
      alert("Please enter text to search.");
      return;
    }

    const results = blogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchResults(results);
  };


   const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      projectdescription: "",
    });
   
    // Handle input change
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
   
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
  
    const handleShowToast = (message) => {
      setToastMessage(message);
      setShowToast(true);
    };
  
    const handleHideToast = () => {
      setShowToast(false);
    };
  
    // Handle form submission
    const handleFormSubmit = async () => {
  
      const payload = {
        fields: [
          { name: "email", value: formData.email },
          { name: "name", value: formData.name },
          { name: "phone", value: formData.phone },
          { name: "projectdescription", value: formData.projectdescription },
        ],
      };
      try {
        const res = await submitContactForm(payload);
        if (res?.inlineMessage === "Thanks for submitting the form.") {
          setFormData({
            name: "",
            email: "",
            phone: "",
            projectdescription: "",
          });
          handleShowToast();
          setToastMessage("We'll get back to you soon!");
        } else {
          handleShowToast();
          setToastMessage("Some error occur#ee3a38. Please try again!");
        }
      } catch (error) {
        console.error("Error submitting contact form:", error);
        handleShowToast();
        setToastMessage("Failed to submit. Please try again later.");
      }
    };

  return (
    <div className="sidebar">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <input
          type="text"
          placeholder="Search..."
          className="search-bar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button
          style={{
            backgroundColor: "#f44336",
            color: "white",
            padding: "8px 12px",
            border: "none",
            cursor: "pointer",
            borderRadius:"4px"
          }}
          onClick={handleSearch}
        >
          Search
        </button>
      </div>

      {/* Display search results */}
      {searchResults.length > 0 && (
        <div className="search-results">
          <h4>Search Results:</h4>
          <ul>
            {searchResults.map((result) => (
              <li key={result.id}>
                <a
                  href={`/blog-detail/${result.id}/${result.slug}`}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {result.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="banner">
        {/* <img src="https://via.placeholder.com/300x250" alt="Banner" /> */}
      </div>
      <div className="qr-code">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={WhatsAppQR} style={{ width: "50%" }} alt="WhatsApp QR" />
          <img src={TelegramQR} style={{ width: "50%" }} alt="Telegram QR" />
        </div>
        <p style={{fontWeight:"600"}}>Scan QR Code for Immediate Contact</p>
      </div>
      <div className="contact-form" style={{position: "sticky", top:"100px", zIndex: "100", backgroundColor: "#fff", padding: "10px", border:"1px solid #ccc"}}>
        <h3>Contact Us</h3>
        <input type="text" placeholder="Your Name" name="name" value={formData.name} onChange={handleInputChange}/>
        <input type="email" placeholder="Your Email" name="email" value={formData.email} onChange={handleInputChange}/>
        <input type="phone" placeholder="Your Mobile Number" name="phone" value={formData.phone} onChange={handleInputChange} />
        <textarea placeholder="Your Message"  name="projectdescription" value={formData.projectdescription} onChange={handleInputChange}></textarea>
        <button style={{backgroundColor:"#f44336"}}  onClick={handleFormSubmit}>Submit</button>
      </div>
    </div>
  );
};

const BlogDetail = () => {
  const { id, slug } = useParams();
  const blog = blogs.find((item) => item.id === parseInt(id, 10));

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  return (
    <div className="blog-detail">
      <SEO
        title={blog.title}
        description={blog.description}
        url={`https://digitalcrew.co.in/blog/${id}/${slug}`}
      />
      {/* <HeroComponent
      bigText={`<span>${blog.title}</span>`}
      // smallText={blog.description}
      blogImage={blog.image}
      page="aboutus"
    /> */}

      <div className="blog-layout">
        <div className="main-content">
          <h2 className="blog-title">{blog.title}</h2>
          <div style={{ textAlign: "center" }}>
  <p 
    style={{  
      fontSize: "14px", 
      border: "1px solid #ee3a38", 
      padding: "2px 6px",
      borderRadius: "5px",
      display: "inline-block", 
      textAlign: "center", 
      fontWeight: "bold",
      color:"#ee3a38"
    }}
  >
    {blog.cateogry}
  </p>
</div>


          {/* <p style={{ fontStyle:"italic",  fontSize: "16px", width:"200px", border:"1px solid #ee3a38", padding:"10px 5px", textAlign:"center"}}>Category: <strong>{blog.cateogry}</strong></p> */}
          <div style={{ textAlign: "center",color:"#ee3a38", margin: "10px 0", color: "#666", fontFamily: "Arial, sans-serif" }}>
          <p style={{ fontStyle: "italic", margin: "5px 0", color:"#ee3a38"}}>Written By <strong>{blog.author}</strong></p>
          <p style={{ fontStyle: "italic", margin: "5px 0" , color:"#ee3a38"}}><strong>{blog.date}</strong></p>
        </div>
          <div className="banner">
            <img src={blog.image} alt={blog.title} />
          </div>
          <div className="blog-content">
            <p
              dangerouslySetInnerHTML={{ __html: blog.description }}
              style={{ color: "black" }}
            />
          </div>
        </div>
        {/* Pass the blogs array to Sidebar for searching */}
        <Sidebar blogs={blogs} />
      </div>
    </div>
  );
};

export default BlogDetail;
