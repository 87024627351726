import React, { useState, useEffect } from 'react'
import { Accordion, Col, Container, Image, Nav, Navbar, NavDropdown, Row, Tab } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import BrandLogo from '../../assests/images/dc-logo.png'
import Fade from 'react-reveal/Fade';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DCLOGOBlack from '../../assests/images/DClogo-footer.png';


function SharedNavbar() {

    let location = useLocation();
    const [activeNavLink, setActiveNavLink] = useState(location.pathname);
    const [servicesDropdownVisible, setServicesDropdownVisible] = useState(false);

    useEffect(() => {
        setActiveNavLink(
            location.pathname === '' ?
                "/home"
                :
                location.pathname.split('/').includes("industries") ?
                    "/industries"
                    :
                    location.pathname.split('/').includes("services") ?
                        "/services"
                        :
                        location.pathname
        );
    }, [location.pathname])

    const [activeSidebar, setActiveSidebar] = useState(false)

    const handlePageScroll = () => {
        var valScrollTop = window.scrollY;
        const siteWrapper = document.querySelector(".navbar-styles");

          const logoImage = document.querySelector(".brandImage");

    // Check if the path contains "/blog-detail"
    if (location.pathname.includes("/blog-detail") || location.pathname.includes("/blog")) {
        // Always use DCLOGOBlack
        logoImage.setAttribute("src", DCLOGOBlack);
        return; // Exit to prevent further logo changes
    }

        if (valScrollTop > 0) {
            if (siteWrapper.classList.contains("headerFixed") === false) {
                siteWrapper.classList.add("headerFixed");
                let brandImageSrc = document.querySelector('.brandImage').setAttribute('src', DCLOGOBlack);
            }
        } else {
            if (siteWrapper.classList.contains("headerFixed")) {
                siteWrapper.classList.remove("headerFixed");
                let brandImageSrc = document.querySelector('.brandImage').setAttribute('src', BrandLogo);
            }
        }
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setActiveSidebar(open);
    };
   
    const updateLogoOnPageLoad = () => {
        const siteWrapper = document.querySelector(".navbar-styles");
        const logoImage = document.querySelector(".brandImage");
    
        if (location.pathname.includes("/blog-detail") || location.pathname.includes("/blog")) {
            // Always use DCLOGOBlack and add headerFixed on page load
            logoImage.setAttribute("src", DCLOGOBlack);
            siteWrapper.classList.add("headerFixed");
        } else {
            // Default behavior for other routes
            logoImage.setAttribute("src", BrandLogo);
            siteWrapper.classList.remove("headerFixed");
        }
    }

    useEffect(() => {

        updateLogoOnPageLoad();

        window.addEventListener('scroll', handlePageScroll)
        return () => {
            window.removeEventListener("scroll", handlePageScroll)
        }
    }, [])

    if (location.pathname === "/landing-page/software-development" ) {
        return null;
    }

    const toggleServicesDropdown = (visible) => {
        setServicesDropdownVisible(visible);
    };

    return (
        <>
            <Navbar expand="lg" className="navbar-styles" >

                <Navbar.Brand href="/">
                    <Image src={BrandLogo} alt='brand name' className={"brandImage"} />
                </Navbar.Brand>
                {/*   <Navbar.Toggle aria-controls="basic-navbar-nav" className="mobile-view" /> */}
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" activeKey={activeNavLink}  >
                        <Nav.Link href="/" eventkey='/home'>Home</Nav.Link>
                        <Nav.Link href="/industries" eventkey='/industries'>Industries</Nav.Link>

                        {/* <Nav.Link href="/services" eventkey='/services'>Services</Nav.Link> */}
                         {/* <div
                                                    style={{
                                                        position: 'relative',
                                                        display: 'inline-block',
                                                        padding: '0 10px',
                                                    }}
                                                    onMouseEnter={() => toggleServicesDropdown(true)}
                                                    onMouseLeave={() => toggleServicesDropdown(false)}
                                                >
                                                    <Nav.Link href="/services" eventKey="/services">
                                                        Services
                                                    </Nav.Link>
                                                    {servicesDropdownVisible && (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '100%',
                                                                left: '0',
                                                                width: '200px',
                                                                backgroundColor: '#fff',
                                                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                                                border: '1px solid #ddd',
                                                                zIndex: 1000,
                                                                opacity: servicesDropdownVisible ? 1 : 0,
                                                                visibility: servicesDropdownVisible ? 'visible' : 'hidden',
                                                                transition: 'opacity 0.3s ease, visibility 0.3s ease',
                                                            }}
                                                        >
                                                            <Link
                                                                to="/services/product-development"
                                                                style={{
                                                                    display: 'block',
                                                                    padding: '10px 15px',
                                                                    textDecoration: 'none',
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                Product Development
                                                            </Link>
                                                            <Link
                                                                to="/services/thinking-and-design"
                                                                style={{
                                                                    display: 'block',
                                                                    padding: '10px 15px',
                                                                    textDecoration: 'none',
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                Thinking and Designing
                                                            </Link>
                                                            <Link
                                                                to="/services/software-development"
                                                                style={{
                                                                    display: 'block',
                                                                    padding: '10px 15px',
                                                                    textDecoration: 'none',
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                Software Development
                                                            </Link>
                                                            <Link
                                                                to="/services/ui-ux-design"
                                                                style={{
                                                                    display: 'block',
                                                                    padding: '10px 15px',
                                                                    textDecoration: 'none',
                                                                    color: '#000',
                                                                }}
                                                            >
                                                               Product Growth
                                                            </Link>
                                                            <Link
                                                                to="/services/research-and-innovative"
                                                                style={{
                                                                    display: 'block',
                                                                    padding: '10px 15px',
                                                                    textDecoration: 'none',
                                                                    color: '#000',
                                                                }}
                                                            >
                                                               Research and Innovation
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div> */}
                        <Nav.Link href="/blog" eventkey='/blog'>Blogs</Nav.Link>

                        <Nav.Link href="/about-us" eventkey='/aboutus'>About Us</Nav.Link>
                        {/*          <Nav.Link href="/blog" eventkey='/'>Blog</Nav.Link> */}
                        {/* <Nav.Link href="/portfolio" eventkey='/portfolio'>Portfolio</Nav.Link> */}
                        <Nav.Link href="/contact-us" eventkey='/contact-us'>Contact Us</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
                <button className='lets-talk-btn web-view' ><a href="/contact-us">LET'S TALK</a></button>
                <div className="menu-icon mobile-view" onClick={toggleDrawer(true)}>
                    <input className="menu-icon__cheeckbox" type="checkbox" />
                    <div>
                        <span></span>
                        <span className='second-bar'></span>
                        <span></span>
                    </div>
                </div>

            </Navbar>

            <Drawer
                anchor={"right"}
                open={activeSidebar}
                onClose={toggleDrawer(false)}
                className='sidebar-drawer'
            >
                <Box
                    role="presentation"

                    className='sidebar-inner'
                >
                    <div className='brand'>
                        <img src={DCLOGOBlack}></img>
                    </div>
                    <div className='links'>
                        <Link rel="canonical" to='/industries' onClick={() => toggleDrawer(false)}>Industries</Link>
                        <Link rel="canonical" to='/services' onClick={() => toggleDrawer(false)}>Services</Link>
                        <Link rel="canonical" to='/blog' onClick={() => toggleDrawer(false)}>Blogs</Link>
                        <Link rel="canonical" to='/about-us' onClick={() => toggleDrawer(false)}>About Us</Link>
                        <Link rel="canonical" to='/contact-us' onClick={() => toggleDrawer(false)}>Contact Us</Link>
                    </div>
                </Box>
            </Drawer>
        </>
    )
}

export default SharedNavbar